import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import SearchBox from './SearchBox';
import { RouteName } from 'routes';
import { useRoutes } from 'components/primitives/route';
import { defaultSearchParams } from './constants';
import { useAbilities } from 'components/objects/user';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import Schema from './Schema';
import { makeSimpleText } from 'utils/render';
import DisabledSearchBox from './DisabledSearchBox';
import { useIsPreview } from '../preview';
import { ThemeFontSize } from 'behavior/theme';
import { ButtonLayoutOption } from './constants';

const searchRoute = { routeName: RouteName.Search, params: { q: defaultSearchParams } };
const defaultOptions = {
  boxThemeFontSize: ThemeFontSize.Regular,
  buttonLayout: ButtonLayoutOption.IconOnly,
  buttonThemeFontSize: ThemeFontSize.Regular,
  showThumbnails: true,
  suggestionsThemeFontSize: ThemeFontSize.Regular,
};

const Search = ({ id, className, onFocus, onBlur, isDesignerMode, options = defaultOptions, style }) => {
  const ability = useSearchAbility();
  const isPreview = useIsPreview();
  const [routePath] = useRoutes([searchRoute]);
  const [placeholder, searchText, searchTitle] = useSanaTexts([
    !options.placeholder && 'Search_SearchPrefix',
    'ButtonText_Search',
    'ButtonAltText_SearchProducts',
  ]).texts;
  const disabled = isDesignerMode || (!routePath && isPreview);

  if (ability.isLoading || (!routePath && !disabled))
    return null;

  if (!ability.available)
    return (
      <DisabledSearchBox
        className={className}
        searchText={searchText}
        searchTitle={searchTitle}
        options={options}
        style={style}
      />
    );

  return (
    <>
      {!disabled && <Schema routePath={routePath} />}
      <SearchBox
        inputId={`${id}_input`}
        placeholder={options.placeholder || makeSimpleText(placeholder)}
        routePath={routePath}
        searchText={searchText}
        searchTitle={searchTitle}
        className={className}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        options={options}
        style={style}
      />
    </>
  );
};

Search.propTypes = {
  id: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  isDesignerMode: PropTypes.bool,
  options: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  style: PropTypes.object,
};

export default memo(Search);

const abilityKeys = [AbilityTo.ViewCatalog];

function useSearchAbility() {
  const { isLoading, abilities } = useAbilities(abilityKeys);
  return {
    isLoading,
    available: !isLoading && abilities[0] === AbilityState.Available,
  };
}
